'use client'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import LeavePageGuard from '@tebuto/guards/leave-page/LeavePageGuard'
import { RouteChangesProvider } from '@tebuto/guards/leave-page/useRouteChangeEvents'
import MUIThemeProvider from '@tebuto/layout/MUIThemeProvider'
import SWRConfiguration from '@tebuto/layout/SWRConfiguration'
import { TebutoPersistGate } from '@tebuto/providers/PersistGate'
import Toaster from '@tebuto/providers/ToastContainer'
import TranslationProvider from '@tebuto/providers/TranslationProvider'
import { store } from '@tebuto/state/store'
import { Provider as ReduxProvider } from 'react-redux'

export default function Layout({ children }: { children: React.ReactNode }) {
    return (
        <MUIThemeProvider>
            <ReduxProvider store={store}>
                <TranslationProvider>
                    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="de">
                        <TebutoPersistGate>
                            <Toaster />
                            <SWRConfiguration>
                                <RouteChangesProvider>
                                    <LeavePageGuard>{children}</LeavePageGuard>
                                </RouteChangesProvider>
                            </SWRConfiguration>
                        </TebutoPersistGate>
                    </LocalizationProvider>
                </TranslationProvider>
            </ReduxProvider>
        </MUIThemeProvider>
    )
}
