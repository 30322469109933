'use client'

import { classNames } from '@tebuto/functions/css'
import { useComponentTranslation } from '@tebuto/functions/translation.components'
import { Form, Formik, FormikHelpers, FormikValues, useFormikContext } from 'formik'
import { ReactNode } from 'react'
import FormButtons from '../forms/Buttons'
import PlainModal from './PlainModal'
import { useSelectFirstInput } from './hooks'

export interface ITebutoModal<T extends FormikValues> {
    isOpen: boolean
    title: string
    subTitle?: string
    initialValues?: T
    description?: string
    validationSchema?: any
    validateOnChange?: boolean
    validateOnBlur?: boolean
    onSubmit?: (values: T, formikHelpers: FormikHelpers<T>) => void
    cancelButtonText?: string
    cancelButtonEnabled?: boolean
    confirmButtonEnabled?: boolean
    confirmButtonText?: string
    showResetButton?: boolean
    verticalPadding?: boolean
    className?: string
    children: ReactNode | ReactNode[]
    closeHandler: () => void
    onModalClose?: () => void
    customButtonBar?: ReactNode
}

export default function TebutoModal<T extends FormikValues = FormikValues>({
    initialValues = {} as T,
    onSubmit = () => {},
    validationSchema,
    cancelButtonText,
    confirmButtonText,
    confirmButtonEnabled,
    cancelButtonEnabled = true,
    validateOnBlur = true,
    validateOnChange = true,
    className = '',
    children,
    closeHandler,
    onModalClose = closeHandler,
    showResetButton = true,
    verticalPadding = true,
    customButtonBar,
    ...props
}: ITebutoModal<T>) {
    const { t } = useComponentTranslation()

    cancelButtonText = cancelButtonText || t('cancel', { context: 'action' })

    return (
        <PlainModal {...props} className={className} verticalPadding={verticalPadding} closeHandler={closeHandler} onModalClose={onModalClose}>
            <Formik<T>
                validateOnChange={validateOnChange}
                validateOnBlur={validateOnBlur}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onReset={closeHandler}
                validationSchema={validationSchema}
                validateOnMount={true}
            >
                {({ isValid }) => (
                    <FormContent<T>
                        confirmButtonText={confirmButtonText}
                        confirmButtonEnabled={confirmButtonEnabled !== undefined ? confirmButtonEnabled : isValid}
                        cancelButtonEnabled={cancelButtonEnabled}
                        cancelButtonText={cancelButtonText}
                        showResetButton={showResetButton}
                        verticalPadding={verticalPadding}
                        closeHandler={closeHandler}
                        customButtonBar={customButtonBar}
                    >
                        {children}
                    </FormContent>
                )}
            </Formik>
        </PlainModal>
    )
}

function FormContent<T extends FormikValues>({
    children,
    confirmButtonText,
    confirmButtonEnabled,
    cancelButtonEnabled,
    cancelButtonText,
    showResetButton,
    verticalPadding,
    customButtonBar
}: Partial<ITebutoModal<T>>) {
    const { isSubmitting: loading, isValid, dirty } = useFormikContext<T>()
    const { modalBodyRef } = useSelectFirstInput()
    return (
        <Form className={classNames('flex flex-col flex-grow overflow-hidden', verticalPadding ? 'gap-8' : '')}>
            <div className={classNames('px-6 md:px-10 w-full flex-grow overflow-y-auto', verticalPadding ? 'py-6' : '')} ref={modalBodyRef}>
                {children}
            </div>
            <div className={classNames('border-t border-gray-200 py-4 px-6 md:px-10 bg-primary-25 flex-shrink-0', verticalPadding ? 'mt-4' : '')}>
                {!!customButtonBar && customButtonBar}
                {!customButtonBar && (
                    <FormButtons
                        saveButtonLabel={confirmButtonText}
                        resetButtonLabel={cancelButtonText}
                        loading={loading}
                        showResetButton={showResetButton}
                        saveButtonEnabled={confirmButtonEnabled === undefined ? dirty && isValid : confirmButtonEnabled}
                        resetButtonEnabled={cancelButtonEnabled}
                    />
                )}
            </div>
        </Form>
    )
}
